
import { defineComponent, onMounted, reactive } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { MenuComponent } from "@/assets/ts/components";
import { ErrorMessage, Field, Form } from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import ApiService from "@/core/services/ApiService";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { useToast } from "vue-toastification";
import { ref } from "yup";
import moment from "moment";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Multiselect from "@vueform/multiselect";

const toast = useToast();

export default defineComponent({
  name: "kt-widget-11",
  components: {
    Field,
    Loading,
    Multiselect,
  },
  props: {
    widgetClasses: String,
  },

  data() {
    return {
      isLoading: false,
      fullPage: true,
      isColor: "#007BFF",
      isBackgroundColor: "#A3A3A3",
      isOpacity: 0.5,

      tableData: [],
      listSekolah: [],
      paging: {
        page: 0,
        totalPages: 0,
        size: 10,
        totalElements: 0,
        perPageOptions: [10, 25, 50, 100],
      },
      name: "",
      schoolId: "",
      dateFrom: "",
      dateUntil: "",

      showFilter : false,
      timer: 0,
    };
  },

  beforeMount() {
    const store = useStore();
    store.dispatch(Actions.VERIFY_AUTH_FINANCE);
    this.schoolId = JSON.parse(localStorage.getItem("sekolahId")!);
    this.getListSekolah();
    // this.getData(this.paging.size, this.paging.page);
    setCurrentPageBreadcrumbs("Log Callback VA", ["Data"]);
    this.dateFrom = this.getDateNow();
    this.dateUntil = this.getDateNow();
  },

  computed: {
    to() {
      let highBound = this.from + this.paging.size;
      if (this.paging.totalElements < highBound) {
        highBound = this.paging.totalElements;
      }
      return highBound;
    },
    from() {
      return this.paging.size * this.paging.page;
    },
  },

  methods: {
    getData(size, page) {
      const toast = useToast();

      this.isLoading;

      let schoolId = "";
      if (this.schoolId) {
        schoolId = "" + this.schoolId;
      }

      let dateFrom = "";
      if (this.dateFrom) {
        dateFrom = this.date(this.dateFrom);
      } else {
        dateFrom = this.getDateNow();
      }

      let dateUntil = "";
      if (this.dateUntil) {
        dateUntil = this.date(this.dateUntil);
      } else {
        dateUntil = this.getDateNow();
      }
      // crmv2/main_invoice/invoice/office/log_callback/61e538d20d543336dfa52a96?page=0&size=10&sort=createTime&dir=-1
      ApiService.getWithoutSlug(
        "crmv2/main_invoice/invoice/office/log_callback/" +
          schoolId +
          "?dateFrom=" +
          dateFrom +
          "&dateUntil=" +
          dateUntil +
          "&page=" +
          page +
          "&size=" +
          size +
          "&sort=createTime&dir=-1"
      )
        .then(({ data }) => {
          this.tableData = data.content;
          this.paging.page = data.page;
          this.paging.size = data.size;
          this.paging.totalElements = data.totalElements;
          this.paging.totalPages = data.totalPages;

          this.isLoading = false;
        })
        .catch(({ response }) => {
          this.isLoading = false;
        });
    },

    getListSekolah() {
      ApiService.getWithoutSlug("crmv2/main_school/school/school").then(
        ({ data }) => {
          this.listSekolah = data;
        }
      );
    },

    pilihSekolah(event) {
      // this.schoolId = event;
      var namaSekolah = "";
      if (event) {
        this.schoolId = event;
        let school: any = this.listSekolah.find(
          (item: any) => item._id == this.schoolId
        );
        namaSekolah = school.name;
        this.getData(this.paging.size, this.paging.page);
      } else {
        this.schoolId = "";
        this.tableData = [];
        toast.error("Sekolah Wajib Dipilih");
      }
      localStorage.setItem("schoolName", JSON.stringify(namaSekolah));
      localStorage.setItem("sekolahId", JSON.stringify(event));
      // this.getData(this.paging.size, this.paging.page);
    },
    pilihTanggalAwal() {
      // this.getData(this.paging.size, this.paging.page);
      if (this.schoolId) {
        this.getData(this.paging.size, this.paging.page);
      } else {
        this.tableData = [];
        toast.error("Sekolah Wajib Dipilih");
      }
    },
    pilihTanggalAkhir() {
      // this.getData(this.paging.size, this.paging.page);
      if (this.schoolId) {
        this.getData(this.paging.size, this.paging.page);
      } else {
        this.tableData = [];
        toast.error("Sekolah Wajib Dipilih");
      }
    },

    onChangeSekolah() {
      this.getData(this.paging.size, this.paging.page);
    },

    range(min, max) {
      const arr: any[] = [];
      for (let i = min; i <= max; i++) {
        arr.push(i);
      }
      return arr;
    },

    changePage(item) {
      const page = item - 1;
      this.getData(this.paging.size, page);
    },
    nextPage() {
      const page = this.paging.page + 1;
      this.getData(this.paging.size, page);
    },
    prevPage() {
      const page = this.paging.page - 1;
      this.getData(this.paging.size, page);
    },
    changePageSize(e) {
      const page = this.paging.page;
      this.getData(this.paging.size, page);
    },

    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },

    formatDate: function (date) {
      // return moment(date, "D-MM-YYYY HH:mm").format("D-MM-YYYY HH:mm");
      return moment(date).format("YYYY-MM-DD HH:mm");
    },

    date(val) {
      if (val) {
        return moment(val).format("YYYY-MM-DD");
      } else {
        return "";
      }
    },

    getDateNow() {
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();

      var rslt = yyyy + "-" + mm + "-" + dd;
      return rslt;
    },

    //event
    eventDelaySearch(e) {
      clearTimeout(this.timer) //this timer 0 - set 0 atau clear jika ada input lagi

      this.timer = setTimeout(() => {
          const page = this.paging.page;
          this.getData(this.paging.size, page);
        }, 1000)
    },
    showHideFilter(){
      this.showFilter = !this.showFilter;
    },
  },
});
